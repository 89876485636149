<template
  ><div class="play-win-wrapper">
    <div class="test-wrapper" v-if="!isEmpty && questions && questions.length > 0">
      <transition appear appear-active-class="fade-enter-active">
        <div class="container">
          <BrandSuccessResult
            :taskUserId="taskUserId"
            @close="showResult = false"
            :show="showResult"
          ></BrandSuccessResult>
          <BrandModal />
          <div v-if="media.enabled" class="media-container">
            <BrandMediaContentBox
              v-if="media.type == 'image'"
              :mediaSrc="media.content"
              allowfullscreen
              :aspectRatios="media.ratio"
            >
            </BrandMediaContentBox>
            <BrandVimeoPlayer
              ref="vimeoPlayer"
              :id="currentQuestion.embedVideo"
              v-if="currentQuestion.questionType == 3 && currentQuestion.embedVideo != ''"
              :data="currentQuestion"
            ></BrandVimeoPlayer>
          </div>

          <div v-if="currentQuestion" class="question">
            <VueText>{{ currentQuestion.question }}</VueText>
            <VueListView dir="column" class="option-list">
              <VueButton
                class="option"
                v-for="op in currentQuestion.options"
                :key="op.renderKey"
                :size="sizes.xLarge"
                :contentAlignment="constants.flexAlignment.center"
                :isSingleLine="false"
                outlined
                :class="optionClass(op)"
                @click.prevent="() => selectOption(op)"
                >{{ op.option }}</VueButton
              >
            </VueListView>
          </div>

          <BrandButton
            class="btn-answer"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :disabled="currentQuestion.selectedOption == 0 || buttonFailWait"
            @click.prevent="submitAnswer"
            >{{ submitText }}</BrandButton
          >
        </div>
      </transition>
    </div>
    <div v-else-if="isEmpty" class="empty-container">
      <img src="~@/assets/icons/checkGradient.svg" alt="check" />
      <VueText v-if="isTask" sizeLevel="11" weightLevel="3">Yap Kazanı Tamamladınız</VueText>
      <VueText v-else sizeLevel="11" weightLevel="3">Günün Sorusunu Cevapladınız</VueText>
    </div>
    <div v-else-if="showDailyQuestionMessage" class="empty-info">
      <VueText sizeLevel="6" weightLevel="3"
        >Günün Sorusu 07:00-22:00 saatleri arasında yayımlanmaktadır. Uygulamaya katılıp puan
        kazanma fırsatını kaçırmamak için bu saatler arasında ziyaret etmeyi unutma!</VueText
      >
    </div>
  </div>
</template>
<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandSuccessResult from '@/components/brand/BrandSuccessResult/BrandSuccessResult.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import { Gamification } from '@/services/Api/index';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import StorageHelper from '@/utils/storageHelper';
import { store } from '@/store/store';

export default {
  name: 'BrandTest',
  props: ['taskUserId', 'testId'],
  mixins: [StorageProps, gtmUtils],

  data() {
    return {
      buttonFailWait: false,
      surveyId: 0,
      surveyTakenId: 0,
      questions: null,
      currentQuestion: null,
      surveyFinished: false,
      showResult: false,
      marathonName: 'Ödül Yağmuru',
      isEmpty: null,
      showDailyQuestionMessage: false,
    };
  },
  methods: {
    stopVideo() {
      if (this.$refs.vimeoPlayer) {
        this.$refs.vimeoPlayer.$refs['player_' + this.currentQuestion.embedVideo].pause();
      }
    },
    optionClass(option) {
      let opStyle = '';

      if (
        this.currentQuestion.selectedOption == option.optionId &&
        !this.currentQuestion.answerSubmitted
      )
        opStyle = 'selected-option';
      else if (
        this.currentQuestion.answerSubmitted &&
        this.currentQuestion.selectedOption == option.optionId &&
        this.currentQuestion.rightOptionId != option.optionId
      )
        opStyle = 'wrong-answer';
      else if (
        this.currentQuestion.answerSubmitted &&
        this.currentQuestion.rightOptionId == option.optionId
      )
        opStyle = 'correct-answer';

      return opStyle;
    },
    selectOption(option) {
      this.currentQuestion.selectedOption = option.optionId;
      this.questions[this.currentQuestion.index].selectedOption = option.optionId;
    },
    finishSurvey() {
      this.stopVideo();
      this.isEmpty = true;
      let resultModalConfig = {
        isModalOpened: true,
        modalText: '',
        modalTitle: '',
        isDoubleButton: true,
        modalType: 'alert',
        alertType: '',
        firstButtonText: 'Yaşam Sayfasını Gez',
        firstButtonFn: () => {
          this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Fun.path}`);
        },
        secondaryButtonFn: () => {
          this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`);
        },
        secondButtonText: 'Ana Sayfaya Dön',
      };
      this.pushDataLayerEvent('competition', {
        taskUserId: this.taskUserId,
        name: this.marathonName,
        action: this.isSuccess ? 'win' : 'lose',
      });

      //Yarışmanın tamamlanmasıyla dailyQuizAnswer değiştirilerek userDetails tekrar set edilir
      let userDetails = window.insider_object?.user?.custom
        ? { ...window.insider_object.user.custom }
        : new StorageHelper({
            id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
          }).get();
      userDetails.userInsider.dailyQuizAnswer = true;
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL }).set(userDetails);
      store.dispatch('auth/setUserDetails', userDetails);
      if (window.insider_object?.user) window.insider_object.user.custom = userDetails;

      if (!this.surveyFinished) {
        Gamification.playWinFinishSurvey(this.surveyTakenId).then(res => {
          this.surveyFinished = true;

          if (this.taskUserId) {
            this.showResult = this.isSuccess;
          } else {
            if (res.data && res.data.Data.successful) {
              resultModalConfig.modalTitle = 'Tebrikler';
              resultModalConfig.modalText =
                'Yarışmayı başarıyla tamamladınız. Sonucunuzu yarın ana sayfada bulunan “Yarışma Özetin” kısmından görüntüleyebilirsiniz';
              resultModalConfig.alertType = '';
              this.$store.dispatch('app/setModalConfig', resultModalConfig);
            } else {
              this.buttonFailWait = true;
              setTimeout(() => {
                this.buttonFailWait = false;
                resultModalConfig.modalTitle = 'Üzgünüz';
                resultModalConfig.modalText =
                  'Soruyu yanlış cevapladınız. Yarın yeni bir soruyla karşınızda olacağız, Pmaktif’te puan kazanma şansı hiç bitmez!';
                resultModalConfig.alertType = 'error';
                this.$store.dispatch('app/setModalConfig', resultModalConfig);
              }, 2000);
            }
          }
        });
      } else {
        let route = this.taskUserId
          ? `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}`
          : `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`;
        this.$router.push(route);
      }
    },
    submitAnswer() {
      let quIndex = this.currentQuestion.index;

      if (!this.currentQuestion.answerSubmitted) {
        Gamification.playWinSelectOption({
          surveyId: this.surveyId,
          questionId: this.currentQuestion.questionId,
          optionId: this.currentQuestion.selectedOption,
          surveyTakenId: this.surveyTakenId,
        }).then(res => {
          this.questions[quIndex].rightOptionId = res.data.Data.rightOptionId;
          this.currentQuestion.rightOptionId = res.data.Data.rightOptionId;
          this.questions[quIndex].answerSubmitted = true;
          this.currentQuestion.answerSubmitted = true;
          if (this.questions.length == quIndex + 1) {
            this.finishSurvey();
          }
        });
      } else if (this.questions.length > quIndex + 1) {
        this.stopVideo();
        this.currentQuestion = this.questions[quIndex + 1];
      } else {
        this.finishSurvey();
      }
    },
  },
  computed: {
    isTask() {
      return this.taskUserId && this.testId;
    },
    submitText() {
      if (this.surveyFinished) {
        return 'BİTİR';
      } else if (!this.currentQuestion.answerSubmitted) {
        return 'CEVAPLA';
      } else {
        return 'SONRAKİ SORU';
      }
    },
    isSuccess() {
      let success = false;
      for (let i = 0; i < this.questions.length; i++) {
        success =
          this.questions[i].rightOptionId != 0 &&
          this.questions[i].selectedOption != 0 &&
          this.questions[i].rightOptionId == this.questions[i].selectedOption;
        if (!success) break;
      }

      return success;
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    media() {
      if (!this.currentQuestion) {
        return;
      }

      let type = '';
      if (this.currentQuestion.questionType == 3 && this.currentQuestion.embedVideo) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.embed;
      } else if (this.currentQuestion.questionType == 2 && this.currentQuestion.image) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image;
      }

      let enabled = type != '' ? true : false;
      let content = '';
      if (type == 'embed') {
        content = this.currentQuestion.embedVideo;
      } else if (type == 'image') {
        content = this.currentQuestion.image;
      }

      return { type, enabled, content, ratio: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_16_9 };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  components: {
    VueButton,
    VueListView,
    VueText,
    BrandButton,
    BrandMediaContentBox,
    BrandVimeoPlayer,
    BrandSuccessResult,
    BrandModal,
  },
  beforeMount() {
    let serviceCall = null;
    if (this.isTask) {
      serviceCall = Gamification.getSurveyDetail(this.testId, this.taskUserId);
    } else {
      serviceCall = Gamification.getDailyMarathonDetail();
    }

    serviceCall
      .then(res => {
        this.showDailyQuestionMessage = !res?.data?.Data;
        if (res.data && res.data.Data) {
          const {
            Data: {
              surveyDetail: { surveyId, questions, surveyTakenId, marathonName },
            },
          } = res.data;
          this.surveyId = surveyId;
          this.questions = questions;
          this.questions.forEach((element, index, arr) => {
            arr[index] = Object.assign({}, arr[index], {
              rightOptionId: 0,
              selectedOption: 0,
              answerSubmitted: false,
              index: index,
            });
          });
          this.currentQuestion = this.questions[0];
          this.surveyTakenId = surveyTakenId;
          this.marathonName = marathonName;

          if (this.isTask) {
            this.setAppBarTitle('Yap Kazan');
          } else if (marathonName) {
            this.setAppBarTitle(marathonName);
          }

          this.pushDataLayerEvent('competition', {
            taskUserId: this.taskUserId,
            name: this.marathonName,
            action: 'participate',
          });
        }
      })
      .catch(() => {
        this.isEmpty = true;
      });
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';
.play-win-wrapper {
  height: 100%;
  .test-wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    flex: 1;
    height: 100%;
    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      .btn-answer {
        min-height: 70px;
      }
      .question {
        padding: palette-space-level(20);
        // display: flex;
        // flex-direction: column;
        flex: 1;
        // min-height: 250px;
      }
      .option-list {
        padding-top: palette-space-level(20);
        padding-bottom: palette-space-level(30);
        overflow: hidden !important;
      }
      .option {
        margin-bottom: palette-space-level(10);
      }

      .selected-option {
        background-color: palette-color-level('grey', 30) !important;
        color: palette-color-level('white', 100) !important;
      }
      .correct-answer {
        background-color: palette-color-level('green', 10) !important;
        color: palette-color-level('white', 100) !important;
        opacity: 1 !important;
        &:before {
          display: none;
        }
      }
      .wrong-answer {
        background-color: palette-color-level('red', 10) !important;
        color: palette-color-level('white', 100) !important;
        opacity: 1 !important;
        &:before {
          display: none;
        }
      }
    }
  }
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: palette-space-level('50');
  margin: auto;
  margin-top: palette-space-level('50');
  img {
    margin-bottom: palette-space-level('30');
  }
}
.empty-info {
  display: flex;
  text-align: center;
  margin: 60% 10% 0 10%;
}
</style>
