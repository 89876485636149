<template>
  <transition name="slide">
    <div
      v-if="show"
      class="success-result"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: `url('${require('@/assets/bg/modal-bg.svg')}') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)`,
      }"
    >
      <div
        class="success-result-test"
        :style="{
          backgroundSize: 'cover',
          background: `url('${require('@/assets/bg/coins.png')}') no-repeat`,
        }"
      ></div>
      <a @click="$emit('close')">
        <VueIcon
          class="result-close"
          :width="iconCross.width"
          :height="iconCross.height"
          :iconName="iconCross.name"
          :iconColor="iconCross.color"
        ></VueIcon
      ></a>
      <div class="result-text">
        <h2>Tebrikler</h2>
        <span>{{ successText }}</span>
      </div>
    </div>
  </transition>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
export default {
  name: 'BrandSuccessResult',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskUserId: {
      type: Number,
    },
  },
  components: {
    VueIcon,
  },
  computed: {
    iconCross() {
      const iconCross = ICON_VARIABLES.cross;
      iconCross.color = '#ffffff';
      return iconCross;
    },
    successText() {
      return `${this.taskUserId ? 'Yap Kazanı' : 'Yarışmayı'} Başarı ile Tamamladınız`;
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.success-result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  .result-close {
    position: absolute;
    right: 0;
    top: 0;
    margin: palette-space-level(15);
  }
  .success-result-test {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .result-text {
    color: palette-color-level('white', 100);

    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h2 {
      margin-bottom: palette-space-level(20);
    }
  }
}
</style>
