var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[(_vm.show)?_c('div',{staticClass:"success-result",style:({
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      background: ("url('" + (require('@/assets/bg/modal-bg.svg')) + "') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)"),
    })},[_c('div',{staticClass:"success-result-test",style:({
        backgroundSize: 'cover',
        background: ("url('" + (require('@/assets/bg/coins.png')) + "') no-repeat"),
      })}),_c('a',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('VueIcon',{staticClass:"result-close",attrs:{"width":_vm.iconCross.width,"height":_vm.iconCross.height,"iconName":_vm.iconCross.name,"iconColor":_vm.iconCross.color}})],1),_c('div',{staticClass:"result-text"},[_c('h2',[_vm._v("Tebrikler")]),_c('span',[_vm._v(_vm._s(_vm.successText))])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }